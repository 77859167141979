<template>
  <div
    class="form-builder__multiselect"
    :class="schema.styleClasses || ''"
    @click="handleClick"
  >
    <q-select
      ref="select"
      :filled="!schema.noFilled"
      :color="schema.color || undefined"
      :dark="$q.dark.isActive"
      :model-value="value"
      :label="`${schema.label} ${schema.required ? '*' : ''}`"
      :placeholder="schema.placeholder || ''"
      :options="options"
      :loading="isLoading"
      :hint="schema.hint || undefined"
      :error-message="schema.error || undefined"
      :error="!!schema.error || undefined"
      :disable="!!schema.disabled"
      use-input
      fill-input
      input-debounce="500"
      @focus="handleFocus"
      @blur="handleFocusOut"
      @filter="filterFn"
      @filter-abort="abortFilterFn"
      @virtual-scroll="handleScroll"
    >
      <template v-if="schema.icon" v-slot:prepend>
        <q-icon :name="schema.icon" :color="schema.iconColor" />
      </template>

      <template v-slot:option="props">
        <div
          class="q-pa-sm clickable"
          style="cursor: pointer"
          :class="props.selected ? 'bg-success' : ''"
          @click="handleSelect(props.opt)"
        >
          <div class="row items-center">
            <img
              v-if="schema.imagePreview"
              :src="schema.imagePreview(props.opt)"
              style="max-height: 60px; max-width: 70px; object-fit: contain"
              @error="handleImgError($event)"
            />

            <div
              v-if="props.opt !== undefined && props.opt !== null"
              class="col q-px-xs"
            >
              <div v-if="schema.customLabel">
                {{ schema.customLabel(props.opt) }}
              </div>

              <div v-else>
                {{ props.opt }}
              </div>

              <div v-if="schema.description" class="text-caption">
                {{ schema.description(props.opt) }}
              </div>
            </div>

            <div
              v-if="
                props.opt !== undefined && props.opt !== null && schema.atTheEnd
              "
            >
              <q-badge
                v-if="schema.atTheEnd(props.opt)"
                :label="schema.atTheEnd(props.opt).label"
                :color="schema.atTheEnd(props.opt).color"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:append>
        <q-icon
          v-if="schema.value"
          name="close"
          class="cursor-pointer"
          @click.stop="handleSelect(null)"
        />
      </template>
    </q-select>
  </div>
</template>

<script>
export default {
  name: 'Multiselect',
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      page: 0,
      totalPages: 1,
      options: [],
    }
  },
  watch: {
    'schema.value' () {
      this.updateSelectView()
    }
  },
  computed: {
    value () {
      return this.schema.value
        ? ' '
        : ''
    }
  },
  mounted () {
    this.updateSelectView()
  },
  methods: {
    handleFocus (e) {
      typeof this.schema.onFocus === 'function' && this.schema.onFocus(e)
      if (this.$refs.select) {
        this.$refs.select.updateInputValue(this.search, true)
      }
    },
    handleFocusOut (e) {
      typeof this.schema.onFocusOut === 'function' && this.schema.onFocusOut(e)
      this.updateSelectView()
    },
    reset () {
      this.isLoading = false
      this.search = ''
      this.page = 0
      this.totalPages = 1
      this.options = []
    },
    handleImgError (e) {
      if (this.schema.onImageError) {
        this.schema.onImageError(e)
      } else if (this.schema.fallbackImage) {
        e.target.src = this.schema.fallbackImage
      } else {
        console.error(e)
      }
    },
    handleClick (e) {
      typeof this.schema.onClick === 'function' && this.schema.onClick(e)
    },
    updateSelectView () {
      this.$nextTick(() => {
        if (this.$refs.select) {
          this.$refs.select.updateInputValue((this.schema.customLabel && this.schema.customLabel(this.schema.value)) || this.schema.value || '', true)
        }
      })
    },
    handleSelect (option) {
      this.$refs.select.hidePopup()
      typeof this.schema.onChange === 'function' && this.schema.onChange(option, this.schema.value)
      this.updateSelectView()
    },
    abortFilterFn () {
      this.isLoading = false
    },
    getItemsData (data) {
      if (Array.isArray(data)) {
        return { items: data, totalPages: 1 }
      }

      return data
    },
    updateOptions (data) {
      const { items, totalPages } = this.getItemsData(data)

      if (this.page <= 1) {
        this.options = Object.freeze([...items])
      } else {
        this.options = Object.freeze([
          ...this.options,
          ...items
        ])
      }

      this.totalPages = totalPages
      this.isLoading = false
    },
    loadItems (update) {
      this.isLoading = true

      let search = (this.search || '').replace(/  +/g, ' ').trim()

      if (search && search[search.length - 1] !== '*' && search[search.length - 2] !== ':' && !search.includes('%')) {
        search += search[search.length - 1] === ':'
          ? '*'
          : ':*'
      }

      return this.schema.onScroll(search, this.page)
        .then((data) => {
          if (!data || typeof data !== 'object') {
            return Promise.reject(new Error(`Format of received data in field is wrong! Response must be object or array!`))
          }

          this.updateOptions(data)
          return data
        })
        .finally(() => {
          update(() => {
            this.isLoading = false
          })
        })
    },
    handleScroll ({ index, to, ref }) {
      if (index >= to && this.page < this.totalPages && !this.isLoading) {
        this.page += 1

        const update = (fn) => {
          typeof fn === 'function' && fn()
          ref.reset()
        }

        return this.loadItems(update, true)
      }
    },
    filterFn (val, update) {
      if (typeof this.schema.minimumLength === 'number' && this.schema.minimumLength > val) {
        update()
        return
      }

      if (val !== this.search) {
        this.search = val
        this.page = 1
        return this.loadItems(update)
      }

      if (this.isLoading) {
        return
      }

      if (this.options.length <= 0) {
        this.page = 1
        return this.loadItems(update)
      }

      return update()
    }
  }
}
</script>
