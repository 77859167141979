<template>
  <div class="">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :hint="$t('Add a code of an external document.')"
            :disable="disabled"
            :label="$t('Code (optional)')"
            @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
        />
      </div>

      <div class="q-py-sm q-px-xs row">
        <div class="col">
          <form-builder :schema="schema"/>
        </div>

        <div class="col">
          <q-input
            :model-value="additionalPrice"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable=false
            :label="$t('Additional price')"
            @update:model-value="handleAdditionalPriceInput"
          />
        </div>
      </div>
    </div>
    <q-table
        v-if="model.rates.length > 0"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="model.rates"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: model.rates.length, rowsNumber: model.rates.length }"
        :rows-per-page-options="[]"
        :hide-bottom="true"
        virtual-scroll
        binary-state-sort
        flat
        dense
    >
      <template v-slot:body="props">
        <rate-modifier-tiered-table-row
            :prev-row="getPrevRow(props.rowIndex)"
            :next-row="getNextRow(props.rowIndex)"
            :props="props"
            :disabled="disabled"
            :disabled-delete="model.rates.length <= 1"
            :static-mode="staticMode"
            @delete="handleDelete"
            @change="handleChange"
        />
      </template>
    </q-table>

    <div v-if="!staticMode" class="text-left q-pb-md q-pt-sm q-px-xs">
      <div
          class="clickable rounded q-px-sm q-py-md text-caption"
          :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
          :disable="disabled"
          @click="handleAdd"
      >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add"/>

          <span>
            {{ $t('Add a Row') }}...
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import RateModifierTieredTableRow from './RateModifierTieredTableRow.vue'

export default {
  name: 'RateModifierOrderFeeRetail',
  emits: ['change', 'delete', 'rate-change'],
  components: {
    RateModifierTieredTableRow
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    rate: {
      type: Object,
      default () {
        return {}
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          rates: [],
          types: [],
          additionalPrice: null,
        }
      }
    }
  },
  data () {
    return {
      additionalPrice: this.model && this.model.additionalPrice ? this.model.additionalPrice : null,
      types: this.model.types || [],
      typesOptions: [
        {
          name: 'Retail',
          code: 'Orderadmin\\Products\\Entity\\Order'
        },
        {
          name: 'Wholesale',
          code: 'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder'
        },
        {
          name: 'Return',
          code: 'Orderadmin\\Products\\Entity\\Order\\ReturnOrder'
        },
        {
          name: 'Disposal',
          code: 'Orderadmin\\Products\\Entity\\Order\\Disposal'
        }
      ],
      columns: [
        {
          label: this.$t('Min Quantity'),
          name: 'min_volume',
          align: 'center'
        },
        {
          label: this.$t('Max Quantity'),
          name: 'volume',
          align: 'center'
        },
        {
          label: this.$t('Rate'),
          name: 'price',
          align: 'center'
        },
        {
          label: this.$t('Code (optional)'),
          name: 'code',
          align: 'center',
          classes: this.code ? '' : 'd-none',
          headerClasses: this.code ? '' : 'd-none'
        },
        {
          label: '',
          name: 'actions',
          align: 'right',
          classes: this.staticMode
              ? 'd-none'
              : ''
        }
      ]
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'tag',
                label: this.$t('Type'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.types,
                options: this.typesOptions,
                field: 'types',
                customTag: (item) => {
                  if (item && typeof item === 'object') {
                    return item.name
                  } else {
                    const test = this.typesOptions.filter(e => e.code === item)
                    if (test.length > 0) {
                      return test[0].name
                    }
                  }
                },
                customListItem: (row) => {
                  return this.$t(row.name)
                },
                onRemove: (val) => {
                  this.types = this.types.filter(x => x !== val)
                  this.emitChange({
                    ...this.model,
                    types: this.types
                  })
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  this.types = this.types.filter(val => {
                    if (val === value.code) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (isExist) {
                    return
                  }

                  this.types = [...this.types, value.code]
                  this.emitChange({
                    ...this.model,
                    types: this.types
                  })
                }
              }
            ]
          }
        ]
      }
    },
  },
  methods: {
    handleRemove () {
      this.$emit('delete', this.model)
    },
    emitChange (model) {
      this.$emit('change', model)
    },
    emitChangeRate (model) {
      this.$emit('rate-change', model)
    },
    handleMasterOrder () {
      this.splitMasterOrder = !this.splitMasterOrder
      this.emitChange({
        ...this.model,
        splitMasterOrder: this.splitMasterOrder
      })
    },
    getPrevRow (index) {
      const rates = (this.model.rates || [])
      return rates[index - 1] || null
    },
    getNextRow (index) {
      const rates = (this.model.rates || [])
      return rates[index + 1] || null
    },
    handleInput (code) {
      this.emitChange({
        ...this.model,
        code
      })
    },
    handleAdditionalPriceInput (additionalPrice) {
      this.additionalPrice = additionalPrice

      this.emitChange({
        ...this.model,
        additionalPrice
      })
    },
    handleDelete (rowIndex) {
      const update = {
        ...this.model,
        rates: this.model.rates.filter((x, i) => i !== rowIndex)
      }

      this.emitChange(update)
    },
    handleAdd () {
      if (this.disabled) {
        return
      }

      const lastRate = this.model.rates[this.model.rates.length - 1]
      const newRow = lastRate
          ? { price: 0, count: Number(lastRate.count) + 1 }
          : { price: 0, count: 0 }

      const update = {
        ...this.model,
        rates: [
          ...this.model.rates,
          newRow
        ]
      }

      this.emitChange(update)
    },
    handleChange ({ rowIndex, row }) {
      const update = {
        ...this.model,
        rates: [
          ...this.model.rates.slice(0, rowIndex),
          row,
          ...this.model.rates.slice(rowIndex + 1)
        ]
      }

      this.emitChange(update)
    },
  }
}
</script>
