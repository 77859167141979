<template>
  <q-dialog v-model="isOpen" class="modal-exl">
    <q-card>
      <q-card-section class="row items-center">
        <div class="text-h6 text-center q-mr-sm">
          {{ $t('Select accounts') }}
        </div>

        <search
          dense
          is-expandable
          @submit="handleSearch"
        />

        <q-btn
          :color="filterParams && filterParams.filter(x => x.field !== 'state' && x.field !== 'type').length > 0 ? 'teal' : 'grey-3'"
          text-color="dark"
          size="sm"
          class="q-ml-xl"
          icon="filter_list"
          unelevated
          @click="toggleFilter"
        />

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpenFilter"
        :options="{
          defaultFilter: filterParams,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="toggleFilter"
      />

      <q-card-section class="text-center">
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="loading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="balance"
                :props="props"
                auto-width
              >
                {{ props.row.balance }}

                <span v-if="props.row._embedded.currency">
                  {{ props.row._embedded.currency.symbol }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ `${props.row._embedded.owner.name} (${$t('ID')}: ${props.row._embedded.owner.id})` }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="billing"
                :props="props"
              >
                <span v-if="props.row._embedded.rate">
                  {{ `${props.row._embedded.rate.name} (${$t('ID')}: ${props.row._embedded.rate.id})` }}
                </span>
              </q-td>

              <q-td
                key="actions"
                :props="props"
              >
                <div v-if="rate">
                  <q-btn
                    v-if="props.row._embedded.rate && props.row._embedded.rate.id !== rate.id"
                    color="warning"
                    no-caps
                    :label="$t('Reassign')"
                    @click="onRowDblClick(props.row)"
                  />

                  <q-btn
                    v-else-if="!props.row._embedded.rate"
                    color="light-blue-9"
                    no-caps
                    :label="$t('Assign')"
                    @click="onRowDblClick(props.row)"
                  />

                  <q-btn
                    v-else
                    color="negative"
                    no-caps
                    :label="$t('Discard')"
                    @click="handleCancel(props.row)"
                  />
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <confirm-modal ref="confirmModal" />
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'
import ConfirmModal from '../confirm-modal/ConfirmModal.vue'

export default {
  name: 'AccountSelectModal',
  emits: ['submit'],
  components: {
    FilterCollapse,
    Search,
    ConfirmModal
  },
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Balance'),
          name: 'balance',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Billing'),
          name: 'billing',
          align: 'left'
        },
        {
          label: '',
          name: 'actions',
          align: 'right'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      loading: false,
      items: [],
      isOpenFilter: false,
      rate: null,
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'id',
        'created.from',
        'created.to',
        'name',
        'owner',
        'btn-rate'
      ],
      filterParams: [],
      totalAccountsNumber: 0
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    handleCancel (item) {
      const options = {
        description: this.$t('Are you sure that you want to cancel it?')
      }

      return this.$refs.confirmModal.show(options)
        .then(isOk => {
          if (isOk) {
            return this.cancel(item)
          }

          return null
        })
    },
    onRowDblClick (item) {
      if (item._embedded.rate) {
        const options = {
          description: `${this.$t('To account')} ${item.name || this.$t('no name')} (${this.$t('ID')}: ${item.id}) ${this.$t('is assigned billing')} ${item._embedded.rate.name || this.$t('no name')} (${this.$t('ID')}: ${item._embedded.rate.id}). ${this.$t('Are you sure that you want to reassign it?')}`
        }

        return this.$refs.confirmModal.show(options)
          .then(isOk => {
            if (isOk) {
              return this.save(item)
            }

            return null
          })
      }

      return this.save(item)
    },
    cancel (account) {
      return this.$service.accounts.save({ rate: null }, account.id)
        .then(account => {
          this.upsert(account)
          this.$emit('cancel', account)
        })
    },
    save (account) {
      return this.$service.accounts.save({ rate: this.rate.id }, account.id)
        .then(account => {
          this.upsert(account)
          this.$emit('submit', account)
        })
    },
    upsert (item) {
      let hasItem = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          hasItem = true
          return item
        }

        return x
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }

      return this.items
    },
    handleFiltersSubmit (filter) {
      this.onRequest({ pagination: { filter, page: 1 } })
      this.isOpenFilter = false
    },
    toggleFilter () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]

      if (!query.filter) {
        query.filter = []
      }

      if (!query.filter.find(x => x.field === 'state')) {
        query.filter.push({ type: 'in', field: 'state', values: ['active','blocked'] })
      }

      if (!query.filter.find(x => x.field === 'type')) {
        query.filter.push({ type: 'eq', field: 'type', value: 'deposit' })
      }

      if (query.filter.find(x => x.field === 'rate' && x.type === 'isnotnull')) {
        query.filter = query.filter.filter(x => x.type !== 'isnull')
      }

      this.filterParams = query.filter
      this.loading = true

      return this.$service.accounts.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.loading = false
        })
    },
    close () {
      this.isOpen = false
    },
    open (rate) {
      this.isOpen = true
      this.rate = rate
      this.onRequest({ pagination: { page: 1, rowsPerPage: 5 } })
    }
  }
}
</script>
