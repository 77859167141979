<template>
  <div
    class="full-width"
    :style="option.style || ''"
    :class="option.className"
  >
    <div
      style="line-height: 1rem;"
      :class="option.valueClassName || ''"
      :style="option.valueStyle"
    >
      <div v-html="option.value"></div>
    </div>

  </div>
</template>

<script>
// Utils
import { doCopy } from './../../utils/copy-utils'

export default {
  name: 'ActionText',
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  methods: {
    doCopy (content) {
      return doCopy(content)
    }
  }
}
</script>
