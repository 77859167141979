<template>
  <q-dialog
    v-model="isOpen"
    persistent
    class="modal-md"
  >
    <q-card class="text-center">
      <q-card-section class="row q-px-xs">
        <div v-if="options.title" class="col text-h6 no-copy">
          {{ options.title }}
        </div>

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="handleClose"
        />
      </q-card-section>

      <q-card-section v-if="options.description" class="text-center text-h6 no-copy">
        {{ options.description }}
      </q-card-section>

      <q-card-section class="q-pb-none">
        <q-input
          standout="bg-teal text-white"
          :model-value="text"
          :label="options.label || $t('Enter text')"
          type="text"
          @update:model-value="handleInput"
        />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="positive"
          :label="$t('Ok')"
          no-caps
          @click="handleOk"
        />

        <q-btn
          color="negative"
          :label="$t('Discard')"
          no-caps
          @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'ConfirmValueModal',
  props: {
    action: {
      type: String,
      default () {
        return 'check'
      }
    }
  },
  data () {
    return {
      isOpen: false,
      text: '',
      options: {
        requiredText: ''
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    resolve () {},
    reject () {},
    open (options = {}) {
      this.isOpen = true
      this.text = ''
      this.options = options

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(false)
    },
    handleOk () {
      if (this.text !== this.options.requiredText) {
        this.addErrorNotification('The text you entered does not match with required one!')
        return
      }

      this.isOpen = false
      this.resolve(true)
    },
    handleInput (value) {
      this.text = value
    }
  }
}
</script>

<style>
.no-copy {
  user-select: none;
}
.no-copy::selection {
  background: none;
}
.no-copy::-moz-selection {
  background: none;
}
</style>
